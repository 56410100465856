<template>
  <div class="notification">
    <i class="fas fa-bell"></i>
    <span>{{ message }}</span>
    <i class="fas fa-bell"></i>
  </div>
</template>

<script>

export default {

  name: "Notification",

  props: ['message'],

}
</script>

<style lang="scss" scoped>

.notification {
  display: flex;
  align-items: center;
  gap: 10px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  height: fit-content;
  padding: 10px;
  background-color: #00aeef;
  z-index: 99999;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  font-size: 20px;
  text-align: center;

  i {
    animation: ring 4s ease-in-out .7s infinite;
  }

}

@media only screen and (max-width: 425px) {
  .notification {
    top: unset;
    bottom: 0;
    padding: 5px;
    border-radius: 20px 20px 0 0;
  }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }
  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}

</style>